import { useState, useEffect } from 'react';

/**
 * Given a value that changes often, return a value that "trails
 * behind" it, changing at most once every `delay` ms. Useful for
 * query parameters and other computations based on user input.
 */
function useDebouncedValue<T>(value: T, delay = 500): T {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const id = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(id);
  }, [value, delay]);
  return debouncedValue;
}

export default useDebouncedValue;
